import { useEffect, useState } from 'react';

export type ResponsiveSize = 'sm' | 'md' | 'lg' | 'xl';

export default function useWindowSize() {
  const [currentSize, setCurrentSize] = useState<ResponsiveSize>('lg');

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      if (currentWidth <= 640) {
        return setCurrentSize('sm');
      }
      if (currentWidth <= 760) {
        return setCurrentSize('md');
      }
      if (currentWidth <= 1204) {
        return setCurrentSize('lg');
      }
      return setCurrentSize('xl');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return currentSize;
}
