import { DoubleRightOutlined, RightOutlined } from '@ant-design/icons';
import { Card } from '@sicpama/core-components';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getToken } from 'src/services/storage.service';
import { httpNotifyOrder } from 'src/States/Order/repository/orders.http';
import { notifyOrder } from 'src/States/Order/useCases/notifyOrder';

import { orderService, storeTableService } from '../../services';
import { useStoreState } from '../../States';
import { KdsStoreTableOrderResult, OrderItemStatus } from '../../submodules/sicpama-shared';
import { MenuNameComponent } from '../../utils/menu';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from '../../utils/numberLocaleFormat';

import CancelPayment from './CancelPayment';

const InProgressOrderCard = ({
  item,
  loadOrders,
  startAnimation,
}: {
  item: KdsStoreTableOrderResult;
  loadOrders: (loadMore?: boolean) => Promise<void>;
  startAnimation: () => void;
}) => {
  const { t } = useTranslation();
  const [isCalling, setIsCalling] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { id: storeId } = useStoreState((state) => state.storeData);
  const storeData = useStoreState().storeData;

  const onBulkComplete = async () => {
    await orderService.bulkUpdateOrderItems(
      storeId,
      item.tableId,
      item.items.map((i) => i.id),
      {
        orderItemStatus: OrderItemStatus.SERVED,
      },
    );
    loadOrders();
    startAnimation();
  };

  const totalQuantity = item.items.reduce(
    (acc, cur) => acc + parseInt(String(cur.menuQuantity)),
    0,
  );

  const isMoreThanThreeItems = useMemo(() => item.items.length > 3, [item.items]);
  const borderColor = useMemo(
    () => (isMoreThanThreeItems ? '!border-orange-700 !border-4' : '!border-[#9E9E9E] !border-2'),
    [isMoreThanThreeItems],
  );

  return (
    <Card key={item.orderId} className={`shadow-md p-0 ${borderColor} !border-solid rounded-[8px]`}>
      <div className={`bg-white flex ${isExpanded ? '' : 'h-full'}`}>
        <div
          className="rounded-md bg-[#F6F6FF] px-1 py-2 flex flex-col items-center justify-between gap-1 shrink-0 w-[58px]"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          <p className="text-[18px] text-[#393894]">{item.orderSequentialNumber}</p>
          <div>
            <p className="text-[12px] text-center text-[#6A6A6A]">{t('food-truck.order-time')}</p>
            <p className="text-[12px] text-center text-[#6A6A6A]">
              {dayjs(item.printedAt ?? item.createdAt).format('HH:mm:ss')}
            </p>
          </div>
          <div className="w-full h-auto flex items-center justify-center">
            {isMoreThanThreeItems ? (
              <DoubleRightOutlined
                className={`text-[#BB0000] ${isExpanded ? '-rotate-90' : 'rotate-90'}`}
              />
            ) : (
              <RightOutlined
                className={`text-[#5B5B5B] ${isExpanded ? '-rotate-90' : 'rotate-90'}`}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col w-full px-2 h-full">
          <div
            className="flex items-center w-full justify-between"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <p className="text-[26px] font-bold text-[#FC5C0C]">
              {item.phoneNumber?.slice(-4) ?? '####'}
            </p>
            <p className="text-[26px] font-bold text-[#004FA4]">{totalQuantity}</p>
          </div>
          <div onClick={() => setIsExpanded((prev) => !prev)} className="mb-1">
            {(isExpanded ? item.items : item.items.slice(0, 3)).map((orderItem) => {
              return (
                <div key={orderItem.id} className="flex items-start justify-between gap-4">
                  <MenuNameComponent menuSnapshot={orderItem.menuSnapshot} />
                  <p className="text-[21px] text-[#2254A9] font-bold shrink-0 min-w-10">
                    {parseInt(String(orderItem.menuQuantity ?? '0'))}
                  </p>
                </div>
              );
            })}
          </div>

          {isExpanded && (
            <div className="flex w-full items-end justify-end gap-4 mt-auto">
              <p className="font-bold text-[24px] text-[#212121]">{t('food-truck.grand-total')}</p>
              <p className="font-bold text-[26px] text-[#393894]">
                {numberLocaleFormat(
                  parseInt(String(item.grandTotal)),
                  CURRENCY_TO_LOCALE_PRICE[storeData.currency || 'KRW'],
                )}
              </p>
            </div>
          )}

          {!isExpanded && (
            <div className="flex h-[46px] -mx-2 mt-auto">
              <div
                className={`w-1/2 shrink-0 text-white bg-[#FC5C0C] flex items-center justify-center text-center text-[21px] ${
                  isCalling ? 'opacity-50' : ''
                }`}
                onClick={async () => {
                  if (isCalling) return;
                  setIsCalling(true);
                  if (item.items.every((i) => i.status !== OrderItemStatus.COOKED)) {
                    await orderService.bulkUpdateOrderItems(
                      storeId,
                      item.tableId,
                      item.items.map((i) => i.id),
                      {
                        orderItemStatus: OrderItemStatus.COOKED,
                      },
                    );
                  } else {
                    await notifyOrder(getToken, httpNotifyOrder)(item.orderId);
                  }
                  loadOrders();
                  setIsCalling(false);
                }}
              >
                {t('food-truck.call')}
                {` ${item.numberOfSentPickUpMessage ? item.numberOfSentPickUpMessage : ''}`}
              </div>
              <div
                id="change-order-state-button"
                className="w-1/2 shrink-0 text-[#1E1E1E] bg-[#BEBEBE] flex items-center justify-center text-[21px]"
                onClick={onBulkComplete}
              >
                {t('common.completed')}
              </div>
            </div>
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="flex h-[46px]">
          <CancelPayment order={item} onBulkComplete={onBulkComplete} />
          <div
            className={`w-2/4 shrink-0 text-white text-[18px] bg-[#FC5C0C] flex items-center justify-center text-center ${
              isCalling ? 'opacity-50' : ''
            }`}
            onClick={async () => {
              if (isCalling) return;
              setIsCalling(true);
              await storeTableService.sendPickUpNotification(storeId, item.tableId);
              loadOrders();
              setIsCalling(false);
            }}
          >
            {t('food-truck.call')}
            {` ${item.numberOfSentPickUpMessage ? item.numberOfSentPickUpMessage : ''}`}
          </div>
          <div
            id="change-order-state-button"
            className="w-1/4 shrink-0 text-[#1E1E1E] bg-[#BEBEBE] flex items-center justify-center text-center text-[18px]"
            onClick={onBulkComplete}
          >
            {t('common.completed')}
          </div>
        </div>
      )}
    </Card>
  );
};

export default InProgressOrderCard;
