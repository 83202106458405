import { HttpResult } from 'src/services/http';
import { getToken } from 'src/services/storage.service';
import { create } from 'zustand';

import { httpGetStore } from '../../services';
import { KdsStoreResDto, STORE_ATTRIBUTE_NAME } from '../../submodules/sicpama-shared';

interface StoreState {
  storeData: KdsStoreResDto;
  getStoreData: (storeId: number) => Promise<HttpResult<KdsStoreResDto>>;
}

export const useStoreState = create<StoreState>()((set) => ({
  storeData: {} as KdsStoreResDto,
  getStoreData: async (id: number) => {
    const result = await httpGetStore(getToken)(id);
    if (result.success) {
      set({ storeData: result.data });
    }
    return result;
  },
}));

const getAttribute = (name: STORE_ATTRIBUTE_NAME) => (state: StoreState) =>
  state.storeData.attributes?.find((attr) => attr.name === name);

export const StoreSelector = {
  getAttribute,
};
