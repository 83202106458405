/* eslint-disable @typescript-eslint/no-unused-vars */
import type { DrawerProps } from 'antd';
import { Divider, Drawer } from 'antd';
import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import DatabaseIcon from 'src/components/Icons/database';
import HomeIcon from 'src/components/Icons/home';
import { IconProps } from 'src/components/Icons/Icon.interface';
import MenuIcon from 'src/components/Icons/menu';
import PrinterIcon from 'src/components/Icons/printer';
import ReportIcon from 'src/components/Icons/report';
import TransactionIcon from 'src/components/Icons/transaction';
import Select from 'src/components/System/Select';
import { ConfigContext } from 'src/contexts';
import { ROUTES } from 'src/helpers/constants';
import { useStoreState } from 'src/States';
import { useThermalPrinterState } from 'src/States/ThermalPrinter/ThermalPrinterState';

import { STORE_ATTRIBUTE_NAME } from '../../submodules/sicpama-shared';

interface SideNavigationProps {
  open: boolean;
  onClose: () => void;
  placement?: DrawerProps['placement'];
  width?: number | string;
}

interface NavbarLinkProps {
  readonly to: string;
  readonly Icon: FC<IconProps>;
  readonly text: string;
  readonly selected: boolean;
}

const translationPrefix = 'table-status';

function NavbarLink({ to, Icon, text, selected }: NavbarLinkProps) {
  const backgroundIcon = selected === true ? 'bg-indigo-500' : 'transparent';
  const iconColor = selected === true ? 'white' : '#5b5b5b';
  return (
    <NavLink
      to={to}
      className="w-full max-w-[calc(100%-8px)] flex justify-center items-center mb-8 px-2"
    >
      <div
        className={`${backgroundIcon} w-[32px] h-[32px] rounded items-center justify-center flex-shrink-0`}
      >
        <Icon color={iconColor} className=" mx-auto" />
      </div>
      <p className="font-medium text-sm text-gray-500 ml-2 break-words leading-tight flex-1">
        {text}
      </p>
    </NavLink>
  );
}

const SideNavigation: React.FC<SideNavigationProps> = ({
  open,
  onClose,
  placement = 'right',
  width = 160,
}) => {
  const { t } = useTranslation();
  const { setThermalPrinter } = useThermalPrinterState();
  const { changeAppLanguage, currentAppLanguage } = useContext(ConfigContext);

  const { attributes } = useStoreState((state) => state.storeData);
  const kdsHomePath = useMemo(() => {
    const kdsHomePathAttr = attributes?.find(
      (attr) => attr.name === STORE_ATTRIBUTE_NAME.KDS_HOME_PAGE_PATH,
    );
    return kdsHomePathAttr?.value ?? ROUTES.TABLE_STATUS;
  }, [attributes]);

  return (
    <Drawer
      title={
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/sicpamaLogo.png`}
          alt="SicpamaLogo"
          width={100}
          height={20}
        />
      }
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      key={placement}
      width={width}
      className="!bg-[#F6F6FF]"
      bodyStyle={{ paddingLeft: 4, paddingRight: 4 }}
    >
      <NavbarLink
        to={kdsHomePath}
        text={t(`${translationPrefix}.order-status`)}
        Icon={HomeIcon}
        selected={location.pathname.includes(kdsHomePath)}
      />

      <NavbarLink
        to={ROUTES.MENU_MANAGEMENT}
        text={t(`${translationPrefix}.product-management`)}
        Icon={MenuIcon}
        selected={location.pathname.includes(ROUTES.MENU_MANAGEMENT)}
      />

      <NavbarLink
        to={ROUTES.SALE_STATUS}
        text={t(`${translationPrefix}.sales-status`)}
        Icon={ReportIcon}
        selected={location.pathname.includes(ROUTES.SALE_STATUS)}
      />

      <NavbarLink
        to={ROUTES.TRANSACTION_HISTORY}
        text={t(`${translationPrefix}.transaction-history`)}
        Icon={TransactionIcon}
        selected={location.pathname.includes(ROUTES.TRANSACTION_HISTORY)}
      />

      <button
        className="w-full flex justify-center items-center pb-5 px-2"
        onClick={setThermalPrinter}
      >
        <PrinterIcon
          color="#5b5b5b"
          className="w-[32px] h-[32px] items-center justify-center flex-shrink-0"
        />
        <p className="text-sm text-gray-500 break-words leading-tight flex-1">
          {t(`${translationPrefix}.connect-printer`)}
        </p>
      </button>
      <Divider className="divider" />

      <Select
        className="grow-0 mb-4"
        labelClassName="justify-center"
        items={[
          {
            value: 'en',
            label: '🇬🇧',
          },
          {
            value: 'zh',
            label: '🇨🇳',
          },
          {
            value: 'kr',
            label: '🇰🇷',
          },
        ]}
        onSelect={(value: string) => {
          changeAppLanguage(value);
        }}
        value={currentAppLanguage}
      />
      <NavLink to={ROUTES.LOGIN} className="mt-auto mb-4">
        <p className="text-center text-sm text-gray-500">{t(`${translationPrefix}.log-out`)}</p>
      </NavLink>
    </Drawer>
  );
};

export default SideNavigation;
